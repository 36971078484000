import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../components/login.vue'
import home from '../components/home.vue'
import dashboard from '../components/dashboard.vue'
import userlist from '../components/userlist.vue'
import order from '../components/order.vue'
import Trend from '../components/Trend.vue'
import Seller from '../components/Seller.vue'
import Hot from '../components/Hot.vue'
import Rank from '../components/Rank.vue'
import file from '../components/file.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: login},
  { path: '/', redirect: '/login'},

  { path: '/home', component:home,redirect:'/dashboard',
  children: [
    
    { path: '/dashboard',component: dashboard},
    { path: '/userlist',component: userlist},
    { path: '/order', component: order},
    {path: '/seller',component:Seller},
    {path: '/trend',component:Trend},
    {path:'/rank',component:Rank},
    {path:'/file',component:file},
    {path:'/hot',component:Hot}
    
  ]
  },
]



const router = new VueRouter({
  routes
})

router.beforeEach((to,before,next) => {
  if(to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if(!tokenStr) return next('/login')
  next()
})


export default router
