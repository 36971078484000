<template>
    <div class="com-container">
        <div class="com-chart" ref='hot_ref' v-loading='loading'>

        </div>
    </div>
</template>

<script>
export default {
data(){
    return{
        chartInstance: null,
        allData:[
            {name:"sss",value:"30"},
            {name:"fgf",value:"40"}
        ],
        loading:true
    }
},
mounted(){
    this.initChart()
    this.getData()
    window.addEventListener('resize',this.screenAdapter)
    this.screenAdapter()
},
destroyed(){
        window.removeEventListener('resize',this.screenAdapter)
    },
methods:{
    initChart(){
        this.chartInstance = this.$echarts.init(this.$refs.hot_ref)
        const initOption = {
            backgroundColor:'#fff',
            title: {
                    text:'▎订单状态占比',
                    textStyle: {
                        fontSize:66 ,
                    },
                    left:'5%',
                    top:'20',
                },
            
            tooltip: {
                trigger: 'item',
                formatter:'{b} : {c} ({d}%)'
            },
            legend: {
                top: '5%',
                
                icon:'circle'
            },
            series:[{
                type:'pie',
                radius: ['30%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 10
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: '120',
                    fontWeight: 'bold'
                    }
                },
                 labelLine: {
                    show: false
                },
             }]
        }
        this.chartInstance.setOption(initOption)
    },
   async getData(){
        //获取数据
        const {data:res} = await this.$http.get('status')
        this.allData = res.data
        this.updateChart()
        this.loading=false;
    },
    updateChart(){
        const seriesData = this.allData.map(item=>{
            return{
                name:item.name,
                value:item.value
            }
        })
        const dataOption = {
            series:[{
                data:seriesData,
            }]
        }
        this.chartInstance.setOption(dataOption)
    },
    screenAdapter(){
        const titleFontSize = this.$refs.hot_ref.offsetWidth / 100 * 3.6
        const adapterOption = {
            title:{
                textStyle: {
                        fontSize:titleFontSize,
                    },
                
            },
            series: [{
                emphasis: {
                    label: {
                    show: true,
                    fontSize: titleFontSize,
                    fontWeight: 'bold'
                    }
                },
            }]
        }
        this.chartInstance.setOption(adapterOption)
        this.chartInstance.resize()
    }    
}
}
</script>

<style>

</style>