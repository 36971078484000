<template>
    <div class="space">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>文件传输</el-breadcrumb-item> 
        </el-breadcrumb>

        <el-card>
          <div style="margin-bottom:20px;color:#606266;">上传文件至数据库</div>
            <div>


      <!-- :on-remove="handleRemove"
  :before-remove="beforeRemove" -->
  
                <el-upload
  class="upload-demo"
  action="https://zhende.cool/files"
  multiple
  :limit="1"
  :on-exceed="handleExceed"
  :file-list="fileList"
  :on-success="handleSuccess"
  >
  <el-button size="small" type="primary">点击上传<i class="el-icon-upload el-icon--right"></i></el-button>
  <div slot="tip" class="el-upload__tip">提示：文件内容必须按规定格式写入</div>
</el-upload>    
    </div>
  </el-card>
  
       <div style="height:600px;width:100%;display:inline-block;margin-top:15px;margin-left:5px">  
            <div class="com-chart" ref='hot_ref' v-loading='loading'>

        </div> 
</div>
    </div>
</template>

<script>


export default {
    data() {
      return {
        fileList: [],
        reslist:[
          
        ],
        loading:true,
        text:"",
        readVisible:false,
        readloading:true,
        chartInstance: null,
        allData:[
            {name:"sss",value:"30"},
            {name:"fgf",value:"40"}
        ],
        loading:true
      };
    },
    mounted(){
    this.initChart()
    this.getData()
    window.addEventListener('resize',this.screenAdapter)
    this.screenAdapter()
},
    destroyed(){
        window.removeEventListener('resize',this.screenAdapter)
    },

    methods: {
        initChart(){
        this.chartInstance = this.$echarts.init(this.$refs.hot_ref)
        const initOption = {
            backgroundColor:'#fff',
            title: {
                    text:'▎订单数量',
                    textStyle: {
                        fontSize:66 ,
                    },
                    left:'5%',
                    top:'20',
                },
            
            tooltip: {
                trigger: 'item',
                formatter:'{b} : {c} ({d}%)'
            },
            legend: {
                top: '5%',
                
                icon:'circle'
            },
            series:[{
                type:'pie',
                radius: ['30%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 10
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: '120',
                    fontWeight: 'bold'
                    }
                },
                 labelLine: {
                    show: false
                },
             }]
        }
        this.chartInstance.setOption(initOption)
    },
    async getData(){
        //获取数据
        const {data:res} = await this.$http.get('files')
        this.allData = res
        this.updateChart()
        this.loading=false;
    },
    updateChart(){
        const seriesData = this.allData.map(item=>{
            return{
                name:item.name,
                value:item.value
            }
        })
        const dataOption = {
            series:[{
                data:seriesData,
            }]
        }
        this.chartInstance.setOption(dataOption)
    },
    screenAdapter(){
        const titleFontSize = this.$refs.hot_ref.offsetWidth / 100 * 3.6
        const adapterOption = {
            title:{
                textStyle: {
                        //fontSize:titleFontSize,
                        fontSize:33,
                    },
                
            },
            series: [{
                emphasis: {
                    label: {
                    show: true,
                    fontSize: titleFontSize,
                    fontWeight: 'bold'
                    }
                },
            }]
        }
        this.chartInstance.setOption(adapterOption)
        this.chartInstance.resize()
    },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },

      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      },
      handleSuccess(response, file, fileList){
        
        if(response.success=="yes"){
            this.getData()
        }
      },
    }
}
</script>

<style >
.el-card {
  margin-bottom: 20px;
}
</style>