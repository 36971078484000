<template>

    <div class="com-container">
        <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
                选择年份<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled >2022</el-dropdown-item>
                <el-dropdown-item command="2021" >2021</el-dropdown-item>
                <el-dropdown-item command="2020">2020</el-dropdown-item>
                <el-dropdown-item command="2019">2019</el-dropdown-item>
                <el-dropdown-item command="2018">2018</el-dropdown-item>    
            </el-dropdown-menu>
            </el-dropdown>
        <div class="com-chart" ref='trend_ref' v-loading="loading"></div>
    </div>
    
</template>

<script>
export default {
data(){
    return{
        chartInstance: null,
        allData:{
            month:['1','2','3','4','5','6','7','8','9','10','11','12'],
            data:[
                {name:'上海',data:['1','2','3','4','5','6','7','8','9','10','11','12']},
                {name:'北京',data:['4','2','4','6','7','4','9','3','2','10','17','1']},
                {name:'深圳',data:['3','6','9','8','5','8','7','6','4','14','1','2']},
                {name:'广州',data:['2','4','6','4','7','4','5','8','8','12','13','4']},
                {name:'重庆',data:['6','9','8','7','2','3','4','5','6','7','16','5']},
            ],
            title:'销量趋势'
        },
        loading:true
    }
},
mounted(){
    this.initChart()
    this.getData()
    window.addEventListener('resize',this.screenAdapter)
    this.screenAdapter()
},
destroyed(){
        window.removeEventListener('resize',this.screenAdapter)
    },
methods:{
    initChart(){
        this.chartInstance = this.$echarts.init(this.$refs.trend_ref)
        const initOption = {
            backgroundColor:'#fff',
            title: {
                    text:'▎月销量趋势',
                    textStyle: {
                        fontSize:66 ,
                    },
                    left:60,
                    top:'5%',
                },
            grid: {
                left:'3%',
                top:'35%',
                right:'4%',
                bottom:'10%',
                containLabel: true
            },
            tooltip: {
                    trigger:'axis',
                    axisPointer: 
                    {
                    type: 'shadow'
                    }
                },
                legend: {
                    left:'20',
                    top:'15%',
                    // icon:'circle'
                },
            xAxis: {
                type: 'category',
                boundaryGap:false
            },
            yAxis: {
                type:'value'
            },

        }

        this.chartInstance.setOption(initOption)
    },
  async  getData(){
        //获取数据
        this.loading=true
        const {data:res} = await this.$http.get('trend',{params:{year:2021}})
        this.allData.data=res.data       
        this.updateChart()
        this.loading=false
    },
    updateChart(){
        const color1 = [
            'rgba(73,146,255,0.5)',
            'rgba(124,255,178,0.5)',
            'rgba(253,221,96,0.5)',
            'rgba(255,110,118,0.5)',
            'rgba(88,217,249,0.5)',
        ]

        const color2 = [
            'rgba(73,146,255,0)',
            'rgba(124,255,178,0)',
            'rgba(253,221,96,0)',
            'rgba(255,110,118,0)',
            'rgba(88,217,249,0)',
        ]
        const timeArr = this.allData.month
        const valueArr = this.allData.data
        const  seriesArr = valueArr.map((item,index) => {
            return {
                name:item.name,
                type: 'line',
                data:item.price,
                stack:'xiaoliang',//堆叠图 
                smooth:true,
                areaStyle:{
                    color:new this.$echarts.graphic.LinearGradient(0,0,0,1,[
                        {
                            offset:0,
                            color:color1[index]
                            
                        },//0%的颜色
                        {
                            offset:1,
                            color:color2[index]
                        }//100%颜色
                    ])
                }
            }
        })
        const legendArr = valueArr.map(item =>{
            return item.name
        })
        const dataOption = {
            xAxis: {
                data:timeArr
            },
            legend: {
                data:legendArr
                },
            series: seriesArr,
        }
        this.chartInstance.setOption(dataOption)
    },
    screenAdapter(){
        const titleFontSize = this.$refs.trend_ref.offsetWidth/100*3.6
        const adapterOption = {
            title: {
                    textStyle: {
                        fontSize:titleFontSize ,
                    },
                },
            tooltip:{

                    axisPointer: {
                        lineStyle: {
                            width:titleFontSize,
                        }
                    }
                },
        }
        this.chartInstance.setOption(adapterOption)
        this.chartInstance.resize()
    },
   async  handleCommand(command) {
        this.$message("正在计算，请耐心等待");
        this.loading=true
        const {data:res} = await this.$http.get('trend',{params:{year:command}})
        this.allData=res       
        this.updateChart()
        this.loading=false
      }    
}
}
</script>

<style>
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
.el-dropdown{
    position: absolute !important;
    right: 80px;
    top:30px;
    z-index: 10;
}
</style>