<template>
  <div style="margin-left:5%;margin-top:-1%">
<div style="height:450px;width:750px;display:inline-block;margin-top:15px;margin-left:20px">  
            <Rank></Rank>     
</div>
<div style="height:450px;width:750px;display:inline-block;margin-top:15px;margin-left:20px">  
            <Trend></Trend>     
</div>
<div style="height:450px;width:750px;display:inline-block;margin-top:15px;margin-left:20px">  
            <Seller></Seller>     
</div>
<div style="height:450px;width:750px;display:inline-block;margin-top:15px;margin-left:20px">  
            <Hot></Hot>   
</div>
  </div>
</template>

<script>
import Hot from '../components/Hot.vue'
import Seller from '../components/Seller.vue'
import Trend from '../components/Trend.vue'
import Rank from '../components/Rank.vue'
export default {
    components: {
        Hot,
        Seller,
        Trend,
        Rank
    }
}
</script>

<style>
.el-row {
    margin-bottom: 20px;
    
  }
.el-col {
    border-radius: 4px;
    min-width: 300px;
    margin-bottom: 20px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
</style>